<template>
  <section :class="{ 'm-2': !isErp }">
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="reclaim_products-sale_id"
              v-model="filters.saleId"
              :label="$t('Id da venda')"
            />
          </b-col>
          <b-col>
            <e-person-search
              id="reclaim_products-client"
              v-model="filters.customer"
              :label="$t('Cliente')"
              :only-active="false"
              is-customer
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="reclaim_products-product_classification"
              v-model="filters.productClassification"
              type="vue-select"
              :label="$t('Classificação')"
              :placeholder="$t('Todos')"
              :options="productClassificationsReclaims()"
            />
          </b-col>
          <b-col md="4">
            <e-store-combo
              id="order-store_id"
              v-model="filters.storeId"
              :required="false"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="pay_box_sale-origin"
              v-model="filters.origin"
              :label="$t('Origem')"
              :placeholder="$t('Selecione')"
              type="vue-select"
              :options="saleOriginOptions()"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <FormulateInput
              id="dates"
              v-model="filters.dates"
              type="date-range-picker"
              :label="$t('Período')"
              opens="right"
            />
          </b-col>
          <b-col md="4">
            <e-search-sku
              id="product-name"
              v-model="filters.skuId"
              :required="false"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        id="reclaim_product-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('PAY_BOX_SALE.RECLAIM_ITEMS.NAME'))"
        :fields="fields"
        :items="sales"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(issueDate)="row">
          {{ row.item.issueDate | datetime }}
        </template>

        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :show-update="false"
            :show-delete="false"
            :buttons="[
              {
                icon: 'bootstrap-reboot',
                variant: 'primary',
                title: $t('Retornar produtos'),
                event: 'show-modal-return',
              },
            ]"
            @show-modal-return="showModalReturn(row.item)"
          />
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="reclaim_product-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <modal-return-items
      ref="modalReclaimItems"
      show-reclaim
      :busy="modalBusy"
      @confirm="onConfirmModalReturn"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EStatusBadge,
  EFilters,
  EGridActions,
} from '@/views/components'
import { loadingOverlay, productDomains, saleDomain, statusTypes } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import EPersonSearch from '@/views/components/inputs/EPersonSearch.vue'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import ESearchSku from '@/views/components/inputs/ESearchSku.vue'
import ModalReturnItems from '../pay-box/components/modals/ModalReturnItems.vue'

export default {
  components: {
    BRow,
    EPersonSearch,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    EStatusBadge,
    EFilters,
    EGridActions,
    ModalReturnItems,
    EStoreCombo,
    ESearchSku,
  },

  mixins: [statusTypes, saleDomain, productDomains, loadingOverlay],

  data() {
    return {
      fetching: false,
      modalBusy: false,
    }
  },

  computed: {
    ...mapState('pages/pdv/reclaimProducts', {
      sales: 'sales',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
    }),

    isErp() {
      return this.$route.name === 'reclaim-list'
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Venda'),
          key: 'id',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { width: '300px' },
          formatter: val => this.$options.filters.storeName(val),
        },

        {
          label: this.$t('Data'),
          key: 'issueDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '150px' },
          sortable: true,
        },
        {
          label: this.$t('Cliente'),
          key: 'customer.name',
          thClass: 'text-center',
          tdClass: 'text-left',
          formatter: (val, index, item) => item.customer?.name || '-',
          sortable: true,
        },
        {
          label: this.$t('Valor da Venda'),
          key: 'netValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '130px' },
          sortable: true,
          formatter: val => this.$options.filters.currency(val),
        },
      ]
    },
  },

  mounted() {
    if (
      this.$route.query?.storeId ||
      this.$route.query?.skuId ||
      (this.$route.query?.startDate && this.$route.query?.endDate)
    ) {
      this.resetFilters()
      this.setFilters({
        dates: {
          startDate: this.$route.query?.startDate,
          endDate: this.$route.query?.endDate,
        },
        storeId: this.$route.query?.storeId?.toString(),
        skuId: this.$route.query?.skuId?.toString(),
      })
    }

    this.getData()
  },

  methods: {
    ...mapActions('pages/pdv/reclaimProducts', [
      'fetchSalesWithReclaimProducts',
      'saveReclaimItems',
      'setCurrentPage',
      'setPageSize',
      'setFilters',
      'resetFilters',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchSalesWithReclaimProducts()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    async showModalReturn(item) {
      try {
        this.showLoadingOverlay(this.$t('Carregando produtos'))
        await new Promise(resolve => setTimeout(() => resolve(), 100))
        this.$refs.modalReclaimItems.showModalReturn(item.id)
      } catch (error) {
        this.showError({ error })
      } finally {
        this.hideLoadingOverlay()
      }
    },

    async onConfirmModalReturn(returnedForm) {
      try {
        this.modalBusy = true
        await this.saveReclaimItems({ returnedForm })

        this.showSuccess({ message: this.$t('Os produtos foram retornados') })
        this.$refs.modalReclaimItems.hideModal()
        this.getData()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.modalBusy = false
      }
    },
  },
}
</script>

<style></style>
